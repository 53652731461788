import { ChangeDetectorRef, Component } from "@angular/core";
import { DatePipe, DecimalPipe } from "@angular/common";
import { BaseComponent } from "./common/components/base-component";
import { SettingsService } from "./common/services/settings/settings.service";
@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.css"],
	providers: [DatePipe, DecimalPipe],
})
export class AppComponent extends BaseComponent {
	title = "susoft-events";

	constructor( protected cdk: ChangeDetectorRef,
				 private settingService: SettingsService,
	) {
		super(cdk);
        this.addSubscription(
            this.settingService.loadGeneralSettings().subscribe()
        );
	}
}

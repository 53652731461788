import dayjs from 'dayjs';
import { eachDayOfInterval } from 'date-fns';
import { SettingLocalStorageRepository } from '../../user-settings/repository/setting-local-storage-repository';

// For material Date picker
let dateFormat = SettingLocalStorageRepository.getDateFormat();
dateFormat = dateFormat?.replace(/dd/g, 'DD');

// For material Date picker
export const MY_FORMATS = {
    parse: {
        dateInput: 'LL'
    },
    display: {
        dateInput: dateFormat,
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY'
    }
};

export const calculateDates = (from, to) => {
    const interval = {
        start: from,
        end: to,
    };

    return eachDayOfInterval(interval);
};

export const getDiffInDays = (date1: dayjs.Dayjs | Date, date2: dayjs.Dayjs | Date): number => {
    return dayjs(date1).startOf('day').diff(dayjs(date2).startOf('day'), 'day');
}

export class MomentRange {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;

    public static isOverlap(interval1: MomentRange, interval2: MomentRange): boolean {
        if (interval1.startDate.startOf('day').diff(interval2.endDate.startOf('day'), 'day') <= 0
            && interval2.startDate.startOf('day').diff(interval1.endDate.startOf('day'), 'day') <= 0)
            return true;
        return false;
    }

    public static getDatesInRange(range: MomentRange) {
        const daysList = calculateDates(range.startDate.toDate(), range.endDate.toDate());
        const chartDates = daysList.sort((a, b) => { return dayjs(a).diff(dayjs(b), 'd') });
        return chartDates;
    }

}
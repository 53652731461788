import { Default } from "./default";
import {PaymentType} from "./settings-payment-types.model";

export class PaymentSettings extends Default {
    data: any;
    type: PaymentType;
    enableOnlinePayment: boolean;

	constructor(data?: Partial<PaymentSettings>) {
		super(data);

        if (data) {
            this.data = data.data;
            this.type = data.type;
            this.enableOnlinePayment = data.enableOnlinePayment;
        }
	}
}
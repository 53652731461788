export enum DateTimeFormats {
    //Dates format
    DAY_MONTH_YEAR_HYPHEN_SEPARATED = "DD-MM-YYYY",
    YEAR_MONTH_DAY_HYPHEN_SEPARATED = "YYYY-MM-DD",
    DATE_TIME_FORMAT = 'YYYYMMDDHHmmss',
    SERVER_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss',
    DATE_TIME_WITH_MS_FORMAT = 'YYYYMMDDHHmmssSSS',
    DATE_TIME_FORMAT_NO_MS = 'YYYYMMDDHHmm',
    YEAR_MONTH_DAY_24H_MIN_SEC_MILIS = 'YYYY/MM/DD HH:mm:ss.SSSSSS',
    YEAR_MONTH_DAY = 'YYYY/MM/DD',
    YEAR_MONTH_DAY_TOGETHER = 'YYYYMMDD',
    MONTH_NAME_DAY_YEAR = 'MMM DD YYYY',
    MONTH_FULL_NAME_YEAR = 'MMMM YYYY',
    MONTH_FULL_NAME_YEAR_FOR_DATEPICKER = 'MMMM YYYY',
    MONTH_FULL_NAME = 'MMMM',
    MONTH_NAME_YEAR = 'MMM YYYY',
    YEAR_MONTH = 'YYYY/MM',
    YEAR = 'YYYY',
    SHORT_DAY_NAME = 'ddd',
    LONG_DAY_NAME = 'dddd',
    yyyymmdd = YEAR_MONTH_DAY_TOGETHER,
    yyyymmddHHmmss = 'YYYYMMDDHHmmss',
    YEAR_MONTH_DAY_DASH = 'YYYY-MM-DD',
    MONTH_DAY_24H_MIN_SEC = 'ddd, DD/MM HH:mm:ss',
    HOUR_MINUTES_SECONDS_LEADING_ZEROES = 'HH:mm:ss',
    YYYYMM = 'YYYYMM',
    DAY_MONTH_FULL_NAME_YEAR = 'DD MMMM YYYY',
    DAY_MONTH_SHORT_NAME_YEAR = 'dd MMM YYYY',
    yyyyMMddHHmm = 'YYYYMMDDHHmm',
    DAY_MONTH_YEAR = "DD/MM/YYYY",

    //Time formats
    TIME_NO_SECONDS = "hh:mm at",
    HOUR12_MINUTES = "hh:mm a",
    HOUR24_MINUTES = "HH:mm",
    HOUR24_MINUTES_SECONDS = "HH:mm:ss",
    HOUR_24H_MIN_TOGETHER = 'HHmm'
}

import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { TableQueryParams } from "../types/table-query-params.type";

export class BaseNetworkRepository {

    constructor(public http: HttpClient) {
    }

    delete<T>(url: string, withCredentials: boolean = false): Observable<T> {
        return this.http.delete<T>(
            `${url}`, 
            {withCredentials: !!withCredentials});
    }

    get<T>(url: string, withCredentials: boolean = false , headers?: any, params?: any): Observable<T> {
        return this.http.get<T>(
            `${url}`, 
            {withCredentials: !!withCredentials , headers, params});
    }

    post<T>(url: string, data: any, withCredentials: boolean = false, headers?: any): Observable<T> {
        return this.http.post<T>(
            `${url}`, 
            data, 
            {withCredentials: !!withCredentials, headers});
    }

    getTableData(url: string, params: TableQueryParams): Observable<{
        data: any[], total: number}> {
        return this.post<{data: any[], total: number}>(
            url, 
            params,
            true);
    }

    getItemDetails(url: string, id: string): Observable<any> {
        return this.get<any>(`${url}/${id}`, true);
    }
}

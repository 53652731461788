import {Injectable} from '@angular/core';
import {DateTimeFormats} from "../../../common/models/susoft-date-time-format";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import { CompareUtils } from 'projects/main/src/utils/compare-utils';

@Injectable({
    providedIn: 'root'
})
export class DatesService {
    clockFormat: number; //12 | 24;

    constructor() {
    }

    convertDateToDBStringFormat(date: any) {
        if(!(date instanceof Date)){
            date = dayjs(date).toDate();
        }
        return dayjs(date.toDateString())
            .format(DateTimeFormats.SERVER_DATE_TIME_FORMAT);
    }

    convertDateToServerDateTimeFormat(date: any) {
        return dayjs(date).format(DateTimeFormats.yyyymmddHHmmss).toString();
    }

    convertDBStringFormatToDate(date: any) {
        return dayjs(date, [DateTimeFormats.yyyymmdd, DateTimeFormats.yyyymmddHHmmss]).toDate();
    }

    convertDateDBFormatToTimeString(time: any) {
        if (this.clockFormat == 12) {
            return dayjs(time).format(DateTimeFormats.HOUR12_MINUTES).toString();
        }

        return dayjs(time).format(DateTimeFormats.HOUR24_MINUTES).toString();
    }

    /**
     * 
     * @param time HH:mm
     * @returns HH:mm or hh:mm a
     */
    convertTimeFormat(time: any, from = 24, to?: number) {
        to = to ? to : this.clockFormat;

        let fromFormat = from === 24 
            ? DateTimeFormats.HOUR24_MINUTES
            : DateTimeFormats.HOUR12_MINUTES;
        let toFormat = to === 24 
            ? DateTimeFormats.HOUR24_MINUTES
            : DateTimeFormats.HOUR12_MINUTES;

        return dayjs(time, fromFormat).format(toFormat).toString();
    }

    convertTimeToDBFormat(time: any) {
        return dayjs(time).format(DateTimeFormats.HOUR_24H_MIN_TOGETHER).toString();
    }

    convertTimeStringToDate(time: any) {
        if (this.clockFormat == 12) {
            return dayjs(time, DateTimeFormats.TIME_NO_SECONDS).toDate();
        }

        return dayjs(time, DateTimeFormats.HOUR_24H_MIN_TOGETHER).toDate();
    }

    convertYearMonthDateFormat(date: any) {
        return dayjs(date).format(DateTimeFormats.yyyymmddHHmmss);
    }

    isSameMonth(a: string, b: string, format = DateTimeFormats.YYYYMM) {
        let monthA = dayjs(a, format);
        let monthB = dayjs(b, format);
        
        return monthA.isSame(monthB);
    }

    isSameTime(a: string, b: string, format = 'HH:mm') {
        let timeA = dayjs(a, format);
        let timeB = dayjs(b, format);
        
        return timeA.isSame(timeB);
    }

    createMomentFromDateAndTime(date: string | Date, time: string, 
            format = DateTimeFormats.DATE_TIME_FORMAT_NO_MS) {
        date = dayjs(date).format(DateTimeFormats.YEAR_MONTH_DAY_TOGETHER);
        
        return dayjs(date + time.split(":").join(''), format);
    }

    convertDateStringToDate(
        date: string | Date, 
        format = DateTimeFormats.YEAR_MONTH_DAY_TOGETHER) {
        return dayjs(date, format).toDate();
    }

    convertDateToDbFormat = (date): string => {
        return dayjs(date).format(DateTimeFormats.YEAR_MONTH_DAY_TOGETHER);
    };

    compareDates(a: string | Date, b: string | Date): 1 | 0 | -1 {
        return CompareUtils.compareDates(a, b);
    }
}

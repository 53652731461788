import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable()
export class ApiUrlInterceptor implements HttpInterceptor {
	constructor() {}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		let requestUrl = req.url;
		
		if (requestUrl.indexOf('http') === -1) {
			requestUrl = environment.apiUrl + requestUrl;
		}

		req = req.clone({
			url: requestUrl
		});

		return next.handle(req);
	}
}
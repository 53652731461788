export class SettingLocalStorageRepository {
    private static DATE_KEY = 'date-format';
    private static TIME_KEY = 'time-format';
    private static CURRENCY_FORMAT_KEY = 'currency-format';
    private static CURRENCY_KEY = 'currency';
    private static LANGUAGE='language';


    public static setDateFormat(dateFormat: string) {
        localStorage.setItem(this.DATE_KEY, dateFormat);
    }

    public static setTimeFormat(timeFormat: string) {
        localStorage.setItem(this.TIME_KEY, timeFormat);
    }

    public static setCurrencyFormat(currencyFormat: string) {
        localStorage.setItem(this.CURRENCY_FORMAT_KEY, currencyFormat);
    }

    public static setCurrency(currency: string) {
        localStorage.setItem(this.CURRENCY_KEY, currency);
    }

    public static getDateFormat(): string {
        return localStorage.getItem(this.DATE_KEY);
    }

    public static getTimeFormat(): string {
        return localStorage.getItem(this.TIME_KEY);
    }

    public static getDateTimeFormat(): string {
        return `${this.getDateFormat()} ${this.getTimeFormat()}`;
    }

    public static getCurrencyFormat(): string {
        return localStorage.getItem(this.CURRENCY_FORMAT_KEY);
    }

    public static getCurrency(): string {
        return localStorage.getItem(this.CURRENCY_KEY);
    }
    
    public static getLanguage(): string {
        return localStorage.getItem(this.LANGUAGE);
    }

}